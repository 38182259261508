<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title : "Новый документ '" + m.title + "'" }}
      </template>
      <a-form-model v-if="false" v-model="data" :model="modelType" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
      <a-loader v-if="!loaded" />
      <a-form-model
        v-else
        ref="modelType"
        :key="type"
        v-model="data"
        :model="modelHead"
        :errors="errors"
        :config="{ dense: true }"
        @validate="validate($event)"
      />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    m: Object,
    hasData: { type: Boolean, default: false },
    details: Array,
  },
  data() {
    return {
      removeDialogShow: false,
      idEdit: 0,
      suEditShow: false,
      type: null,
      loaded: false,
    };
  },
  computed: {
    model() {
      return this.getModelList(this.m, "form", true);
    },
    modelHead: {
      get() {
        let type = this.id ? "editForm" : "createForm";
        let operation = this?.data?.operation_type || 0;
        let template = "form_" + operation;
        let model = this.calcModel(type, template);

        //this.getModelList(this.m, type, true);
        if (this.hasData) {
          model.forEach(el => {
            if ("parent_id,date_doc,code_doc".split(",").includes(el.name)) {
              el.readonly = true;
            }
          });
        }
        return model;
      },
    },
    editObject() {
      return false;
      return this.getAccess("object.edit", {
        id: this.object_.id,
        access: this.object_?.object_access || [],
      });
    },
  },
  created() {
    this.loaded = false;
    this.type = this.initType || null;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true;
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    "data.operation_type"(v, old) {
      console.log("data.operation_type", v, old, this.loaded, this.data);
      if (this.loaded && old !== null) {
        this.data.store_id = null;
        this.data.store_from_id = null;
      }
    },

    value1() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.type = 1;
          //  this.data.status = 0;
        }
      }
    },
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      let d = { row: { id } };

      if (!this.id) {
        this.$emit("open", d);
        //this.$router.push({ name: this.m.routeName, params: { id } });
      }
    },
    afterFetchData(r) {
      this.loaded = true;
    },
  },
};
</script>